import React from 'react'
import Images from "../../../assets/images/Images";
import Icon from "../../../assets/icons/Icon";

export default function ContactOne() {
    const contactDetails = [
        {
            icon: "email",
            value: "info@xortlogix.com",
            link: "mailto:info@xortlogix.com"
        },
        {
            icon: "phone",
            value: "+92 308 5022119",
            link: "tel:info@xortlogix.com"
        },
        {
            icon: "location",
            value: "30 N Gould St #23423 Sheridan, WY 82801 USA"
        },
    ];
    const randStr = () => Math.random().toString(32).substr(2);
    return (
        <div className={"grid grid-cols-1 sm:grid-cols-2 gap-5 xl:gap-16 justify-center items-center animate-700"}>
            <div className={"mx-auto"}>
                <Images className={"mx-auto"} src={"image31"} />
            </div>
            <div>
                <div className={"p-10 xl:px-14 sm:py-16 flex flex-col gap-4 sm:gap-7 bg-lily-900 rounded-2xl animate-700"}>
                    <Images src={"Logo Circle"} className={"w-14"} />
                    <h2 className={"text-3xl animate-700 sm:text-4xl sm:leading-[3rem]"}>Schedule a call with us to see how we can help you</h2>
                    <p>Exploring Solutions Together: Book a Consultation for Personalized Assistance</p>
                    <p className={"bg-black/10 w-full h-0.5 animate-700"} />
                    {contactDetails.map(con => {
                        return (
                            <div key={randStr()} className={"flex gap-3"}>
                                <span><Icon name={con.icon} /></span>
                                <a
                                    rel={"noreferrer noopener nofollow"}
                                    href={con.link}
                                    className={`${con.link ? "link" : ""} w-max para-lg-thin`}>
                                    {con.value}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>

    )
}
