import React from "react";
import Hero from "./assets/Hero";
import About from "./assets/About";
import Services from "./assets/Services";
import Section from "./assets/Section";
import Portfolio from "./assets/Portfolio";
import WhyWe from "./assets/WhyWe";
import ContactOne from "./assets/ContactOne";
import Testimonial from "./assets/Testimonial";
import Contact from "./assets/Contact";

function Home() {
  const externalGap = (className = "") => (
    <div className={`w-full h-12 sm:h-[4.5rem] ${className}`} />
  );
  const externalGap2x = (className = "") => (
    <div className={`w-full h-24 sm:h-36 ${className}`} />
  );
  const spacing =
    "py-10 sm:py-14 px-5 sm:px-8 lg:px-10 xl:px-14 rounded-[2rem] animate-700";
  return (
    <>
      {/* Hero Section -- -- */}
      <section
        id={"home"}
        className={
          "mt-10 py-10 px-5 sm:px-10 lg:px-14 lg:py-0 lg:pr-5 bg-off-white-900 rounded-[2rem] animate-700"
        }
      >
        <Hero />
      </section>
      {externalGap()}
      {/* About Section -- -- */}
      <section id={"about"} className={spacing}>
        <About />
      </section>
      {externalGap()}
      {/* Services Section -- -- */}
      <section id={"services"}>
        <h2
          className={
            "block sm:hidden heading capitalize font-semibold text-center"
          }
        >
          {" "}
          Our Services
        </h2>
        <div
          className={`sm:bg-off-white-900 ${spacing} !px-0 sm:!px-8 sm:py-8 md:py-14`}
        >
          <Services />
        </div>
      </section>
      {externalGap()}
      {/* Have Any Projects in Mind? (Section) -- -- */}
      <section className={spacing}>
        <Section />
      </section>
      {externalGap()}
      {/* Portfolio Section -- -- */}
      <section id={"portfolio"}>
        <h2
          className={
            "block sm:hidden heading capitalize font-semibold text-center"
          }
        >
          {" "}
          Our Portfolio
        </h2>
        <div
          className={`sm:bg-off-white-900 ${spacing} !px-0 sm:!px-8 sm:py-8 md:py-14`}
        >
          <Portfolio />
        </div>
      </section>
      {externalGap()}
      <section className={`${spacing} sm:px-4 md:px-8 lg:px-12`}>
        <WhyWe />
      </section>

      {externalGap()}

      <section className={`${spacing} sm:bg-off-white-900 sm:px-4 md:px-8 lg:px-12`}>
        <ContactOne />
      </section>

      {externalGap2x()}
      <section className={`${spacing} sm:bg-white !px-0`}>
        <Testimonial />
      </section>
      {externalGap2x()}
      <section id={"contact"} className={`rounded-[2rem] animate-700`}>
        <Contact />
      </section>
      <chat-widget

        location-id="fGEdlT60cGv0coO8AjhA"
        use-email-field="true"
        agency-name="Xort Logix"
        show-consent-checkbox="true"
      ></chat-widget>
    </>

  );
}

export default Home;
