import Home1 from "./Store/Home1.jpg";
import Home2 from "./Store/Home2.webp";
import Home3 from "./Store/Home3.jpg";
import Home4 from "./Store/Home4.webp";
import Home5 from "./Store/Home5.jpg";
import Home6 from "./Store/Home6.jpg";
import Home7 from "./Store/Home7.png";
import Logo from "./Store/Logo.png";
import LogoDark from "./Store/Logo Dark.png";
import LogoCircle from "./Store/Logo Circle.png";
import LogoCircleDark from "./Store/Logo Circle Dark.png";
import Computer3d from "./Store/computer3d.png";
import React from "react";
import Accordion from "./Store/AccordionApp.png";
import ChainDev from "./Store/chain-dev.png";
import Estate from "./Store/estate.png";
import EstateTablet from "./Store/estate-tablet.png";
import FileManagement from "./Store/file-management.png";
import FileManagementTablet from "./Store/file-management-tablet.png";
import Germen from "./Store/Germen.png";
import Glassmorphic from "./Store/Glassmorphic.png";
import Justice from "./Store/Justice.png";
import Pegasus from "./Store/Pegasus.png";
import VBud from "./Store/vBud.png";
import Image31 from "./Store/image31.png";
import ThankYou from "./Store/thankyou.png";
import Abraham from "./teamImg/abraham.jpg";
import Husisan from "./teamImg/husian.jpeg";
import Rehmat from "./teamImg/rehmat.png";
import Saddam from "./teamImg/saddam.jpeg";
import Shoaib from "./teamImg/sohaib.jpeg";
import Kamran from '../teams/Kamran_20_11zon.webp'
import Adil from '../teams/Adilanees.jpg'
import Awais from '../teams/Awais_15_11zon.webp'
import Faraz from '../teams/Faraz_16_11zon.webp'
import Imala from '../teams/Imala Muneer_19_11zon.webp'
import hasan from '../teams/hassanjs_18_11zon.webp'
import Laraib from '../teams/Laraib Chaudhary1_21_11zon.webp'
import Malik from '../teams/Malik_22_11zon.webp'
import mamfaiza from '../teams/mamfaiza_23_11zon.webp'
import Rida from '../teams/Rida Asif_25_11zon.webp'

import Usmanzaheer from '../teams/Usman Zaheer_4_11zon.webp'

import Saqib from '../teams/Saqib Ali Shahzad_27_11zon.webp'
import sufyan from '../teams/Sufianch.jpg'
import ShoaibAlam from '../teams/ShoaibAlam_2_11zon.webp'

import Anwar from "../teams/Anwar_13_11zon.webp"
import zohaibswati from '../teams/Zohaib Sawati_7_11zon.webp'

import sheyan from '../teams/Sheyan_29_11zon.webp'

import Shahmeer from '../teams/Shahmeerkhan_28_11zon.webp'



import AhmedShehzad from '../teams/Ahmed Shehzad_10_11zon.webp'

import Alihasan from '../teams/Alihasanjpg_11_11zon.webp';
import Alisha from '../teams/Alisha .jpg';

import Survey1 from './Store/Survey/screencapture-api-leadconnectorhq-widget-survey-Afe7it7D7HgJAWqb2vAb-2024-08-01-02_14_52.webp';
import Survey2 from './Store/Survey/screencapture-api-leadconnectorhq-widget-survey-OIUMnKvAfiBKSJq9T6uz-2024-08-01-02_13_09.webp';
import Survey3 from './Store/Survey/screencapture-api-leadconnectorhq-widget-survey-XWjVRQrF2hkc300ckVhi-2024-08-01-02_15_45.webp';
import Barberfunnel from './Store/Projects-web/Barberfunnel.webp';
import Digitalbussiness from './Store/Projects-web/Digital bussiness.webp';
import fitness from './Store/Projects-web/fitnessfunnel.webp';
import Healthcare from './Store/Projects-web/Healthcare.webp';
import RealState from './Store/Projects-web/RealState.webp';
import SAAS from './Store/Projects-web/SAAS Solution.webp';
import Barber2 from './Store/Projects-web/Xortlogic BARber.webp';
import lightDashboard from './Store/Dashboards/Dashboard (light mode) 5.webp';
import Darkdashboard from './Store/Dashboards/Dashboard(Dark Mode).webp'
import form1 from './Store/forms/Form.png';
import form2 from './Store/forms/form2.png';
import Sajjad from '../teams/Sajjad Ahmad_26_11zon.webp';

import Abdul_Hanan from '../teams/Hannan Madni.jpeg';
import ABasit from '../teams/A-B.jpg'
import MIdrees from '../teams/IdreesMajid.jpeg'
import Tayyab from '../teams/tayyab .png'
import touseef from '../teams/touseef ur rehman.png'
import ranajunaid from '../teams/Rana junaid.jpg'
import Malikhuzaifa from '../teams/mailkhuzaifa.png'
import malikjunaid from '../teams/MAlik junaid.jpg'
import Abdullahrasheed from '../teams/Abdullah Rasheed.jpg'
import Babarshazad from '../teams/Babar shahzad.jpg'
import khuchkool from '../teams/khuchkhol.jpg'
import MShoaib from '../teams/shoaib.bd.jpg';
import IrfanAhmed from '../teams/Irfanahmed.jpg'
const imageMap = {
  logo: Logo,
  Home1: Home1,
  Home2: Home2,
  Home3: Home3,
  Home4: Home4,
  Home5: Home5,
  Home6: Home6,
  Home7: Home7,
  "Logo Dark": LogoDark,
  "Logo Circle": LogoCircle,
  "Logo Circle Dark": LogoCircleDark,
  computer3d: Computer3d,
  "zeta dashboard": Glassmorphic,
  "accordion app": Accordion,
  "chain dev": ChainDev,
  estate: Estate,
  "estate tablet": EstateTablet,
  "file management": FileManagement,
  "file management tablet": FileManagementTablet,
  germen: Germen,
  justice: Justice,
  pegasus: Pegasus,
  "v bud": VBud,
  image31: Image31,
  "thank you": ThankYou,
  form1: form1,
  form2: form2,
  Survey1: Survey1,
  Survey2: Survey2,
  Survey3: Survey3,
  Barberfunnel: Barberfunnel,
  Digitalbussiness: Digitalbussiness,
  fitness: fitness,
  Healthcare: Healthcare,
  RealState: RealState,
  SAAS: SAAS,
  Barber2: Barber2,
  "light dashboard": lightDashboard,
  Darkdashboard: Darkdashboard,
  abraham: Abraham,
  husian: Husisan,
  saddam: Saddam,
  shoaib: Shoaib,
  rehmat: Rehmat,
  kamran: Kamran,
  Adil: Adil,
  Awais: Awais,
  Imala: Imala,
  Faraz: Faraz,
  hasan: hasan,
  Laraib: Laraib,
  Malik: Malik,
  mamfaiza: mamfaiza,
  Rida: Rida,
  Usmanzaheer: Usmanzaheer,
  Saqib: Saqib,
  Sufyan: sufyan,
  ShoaibAlam: ShoaibAlam,
  "Zohaib Sawati": zohaibswati,
  sheyan: sheyan,
  Shahmeer: Shahmeer,
  "Ahmed Shehzad": AhmedShehzad,
  Alihasan: Alihasan,
  Alisha: Alisha,
  Anwar: Anwar,
  Sajjad: Sajjad,
  Hannan: Abdul_Hanan,

  Abdulbasit: ABasit,
  MIdrees: MIdrees,
  Tayyab: Tayyab,
  touseef: touseef,
  ranajunaid: ranajunaid,
  Malikhuzaifa: Malikhuzaifa,
  malikjunaid: malikjunaid,
  Abdullahrasheed: Abdullahrasheed,
  Babarshazad: Babarshazad,
  khuchkool: khuchkool,
  MShoaib: MShoaib,
  IrfanAhmed: IrfanAhmed

};

export default function Images({ src, className, ...props }) {
  const img = imageMap[src] || src;
  return <img {...props} className={className} src={img} alt="Error" />;
}

