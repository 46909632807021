import React from 'react';
import Icon from '../../../assets/icons/Icon';
import testimonialJson from '../../../assets/json/testimonial.json';
// import TestimonialSlider from './TestimonialSlider'; // Commented out since using iframe

export default function Testimonial() {
    const { testimonial } = testimonialJson;

    return (
        <div className="flex flex-col gap-16">
            {/* Total Rating */}
            <div className="inline-flex mx-auto justify-center items-center bg-black p-2.5 gap-4 rounded-full">
                <div>
                    <span className="flex items-center justify-center rounded-full bg-off-white-900 w-9 h-9">
                        <Icon name="star" />
                    </span>
                </div>
                <h2 className="capitalize text-white pr-2.5">Rated 5/5 by Our Clients</h2>
            </div>

            {/* Heading */}
            <h2 className="heading-lg text-center mx-auto max-w-[650px]">
                Words of praise from others about our presence.
            </h2>

            {/* Testimonial Section */}
            <div className="relative w-full overflow-hidden testimonial">
                <div className="absolute overlay-gradient-testimonial to-left" />
                <div className="absolute overlay-gradient-testimonial to-right" />

                <iframe
                    className="lc_reviews_widget"
                    src="https://services.leadconnectorhq.com/reputation/widgets/review_widget/fGEdlT60cGv0coO8AjhA"
                    frameBorder="0"
                    scrolling="no"
                    className="w-full h-80"
                    title="Testimonial Reviews"
                />
            </div>
        </div>
    );
}
