import React, { useEffect } from "react";

function Calendar() {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <iframe
        src="https://api.leadconnectorhq.com/widget/group/52UaEpTy3kFYYRoVw7hD"
        style={{ width: '100%', border: 'none', height: '900px', overflow: 'hidden' }}
        scrolling="no"
        id="66eb028ba1b0536fc6e4ea6f_1726682685881"
        title="Booking Calendar" // Adding a title for accessibility
      ></iframe>
    </div>
  );
}

export default Calendar;  